// import React, { useEffect, useMemo, useState } from "react";
// import cloneDeep from "lodash/cloneDeep";
// import throttle from "lodash/throttle";
// import Pagination from "rc-pagination";
// import "rc-pagination/assets/index.css";
// import { allData } from "./constants";
// import storage from "@/utils/storage";
// import axios from "axios";
// import "./transaction.css";
// import MervIcon from "@/assets/mervIcon.svg";
// import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
// import { useInvestedCoins } from "../api/getInvestedCoins";
// import yellowt from "@/assets/yellow-t.png";
// import CurrencyFormat from "react-currency-format";
// import {
//   Box,
//   Card,
//   CardContent,
//   CircularProgress,
//   Grid,
//   Paper,
//   Stack,
//   styled,
//   Typography,
//   useTheme,
// } from "@mui/material";
// import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";
// import { MantineProvider } from "@mantine/core";

// const MyPayments = () => {
//   const countPerPage = 10;
//   const dataWallet = useWalletAmount({});
//   const coins = useInvestedCoins({});
//   const [value, setValue] = React.useState("");
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const [totalRecords, setTotalRecords] = React.useState(10);
//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState([
//     {
//       LotteryName: "",
//       amount: 0,
//       commissionAmount: "",
//       createdDate: "",
//       lotteryWinningPrize: "0",
//       payoutAmount: "",
//       transactionId: "",
//       transactionStatus: "",
//       userName: "",
//     },
//   ]);
//   const transformData: any = (data) => {
//     return data.map((item) => {
//       return {
//         LotteryName: item.LotteryName || "N/A",
//         amount: item.amount || "N/A",
//         commissionAmount: item.commissionAmount || "N/A",
//         createdDate: item.createdAt || "N/A",
//         lotteryWinningPrize: item.lotteryWinningPrize || "N/A",
//         payoutAmount: item.payoutAmount || "N/A",
//         transactionId: item.transactionId || "N/A",
//         status: item.status || "N/A",
//         userName: item.userName || "N/A",
//       };
//     });
//   };

//   console.log(transformData(data));
//   const [collection, setCollection] = React.useState(
//     cloneDeep(allData.slice(0, countPerPage))
//   );
//   const searchData = React.useRef(
//     throttle((val) => {
//       const query = val.toLowerCase();
//       setCurrentPage(1);
//       const data = cloneDeep(
//         allData
//           .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
//           .slice(0, countPerPage)
//       );
//       setCollection(data);
//     }, 400)
//   );

//   React.useEffect(() => {
//     if (!value) {
//       updatePage(1);
//     } else {
//       searchData.current(value);
//     }
//   }, [value]);

//   useEffect(() => {
//     getData(value, 1);
//   }, [value]);

//   const getData = async (value, p) => {
//     let token = storage.getToken();
//     let pageLimit;
//     pageLimit = value != "" ? totalRecords : 10;
//     const payable = {
//       page: p,
//       limit: pageLimit,
//     };
//     setLoading(true);
//     await axios
//       .post(
//         `${process.env.API_URL_PREFIX}/api/v1/user/myPaymentList?search=${value}`,
//         payable,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Access-Control-Allow-Origin": "*",
//           },
//         }
//       )
//       .then((res) => {
//         setData(res.data.data);
//         setTotalRecords(res.data.paymentPageCount);
//       })
//       .catch((err) => {
//         console.log(err);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
//   const updatePage = (p) => {
//     setCurrentPage(p);
//     const to = countPerPage * p;
//     const from = to - countPerPage;
//     setCollection(cloneDeep(allData.slice(from, to)));
//     getData(value, p);
//   };

//   const Item = styled(Paper)(({ theme }) => ({
//     background: "transparent",
//     display: "flex",
//     justifyContent: "flex-start",
//     alignItems: "flex-start",
//   }));

//   var cardStyle = {
//     display: "block",
//     transitionDuration: "0.3s",
//     height: "auto",
//     background: "#050518",
//     boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
//     borderRadius: "20px",
//   };

//   const columns = useMemo<MRT_ColumnDef<any>[]>(
//     () => [
//       {
//         accessorKey: "userName",
//         filterVariant: "text",
//         header: "User Name",
//         size: 20,
//       },
//       {
//         accessorKey: "transactionId",
//         filterVariant: "text",
//         header: "Transaction Id",
//         size: 20,
//       },

//       {
//         accessorKey: "createdDate",
//         filterVariant: "text",
//         header: "Created At",
//         size: 20,
//       },
//       {
//         accessorKey: "commissionAmount",
//         filterVariant: "text",
//         header: "Commission Amount",
//         size: 20,
//       },

//       {
//         accessorKey: "amount",
//         header: "Amount",
//         size: 40,
//         Cell: ({ cell }) => (
//           <Box>
//             {cell.getValue<number>()?.toLocaleString?.("en-US", {
//               style: "currency",
//               currency: "USD",
//               minimumFractionDigits: 0,
//               maximumFractionDigits: 0,
//             })}
//           </Box>
//         ),
//       },

//       {
//         accessorKey: "status",
//         header: "Status",
//         size: 40,
//         Cell: ({ cell }) => (
//           <Box
//             sx={() => ({
//               color:
//                 cell.getValue<string>() === "Success"
//                   ? "#95D23D"
//                   : cell.getValue<string>() === "Loose"
//                   ? "#FF9900"
//                   : "#FF4545",
//               borderRadius: "4px",
//               border:
//                 cell.getValue<string>() === "Success"
//                   ? "1px solid rgba(149, 210, 61, 0.41)"
//                   : cell.getValue<string>() === "Loose"
//                   ? "1px solid rgba(231, 214, 61, 0.5)"
//                   : "1px solid rgba(255, 69, 69, 0.4)",
//               backgroundColor:
//                 cell.getValue<string>() === "Success"
//                   ? "rgba(149, 210, 61, 0.19)"
//                   : cell.getValue<string>() === "Pending"
//                   ? "rgba(231, 214, 61, 0.1)"
//                   : "rgba(255, 69, 69, 0.05)",
//               maxWidth: "9ch",
//               padding: "4px",
//             })}
//           >
//             {cell.getValue<string>() === "Pending"
//               ? "N/A"
//               : cell.getValue<string>()}
//           </Box>
//         ),
//       },
//     ],
//     []
//   );
//   return (
//     <Box sx={{ height: "auto" }}>
//       <Grid container sx={{ mt: { md: useTheme().spacing(0.5), xs: "10px" } }}>
//         <Grid item xs={12} md={12}>
//           {" "}
//           <Card
//             style={cardStyle}
//             sx={{
//               p: { md: useTheme().spacing(0.3), xs: "0px" },
//               m: useTheme().spacing(0.2),
//             }}
//           >
//             <CardContent>
//               <Stack spacing={2}>
//                 <Item sx={{alignItems:"center", justifyContent:"space-between"}}>
//                     {" "}
//                     <Typography
//                       component="h4"
//                       sx={{
//                         fontSize: { xs: "20px", md: "22px" },
//                       }}
//                     >
//                       My Payments
//                     </Typography>
//                     <Typography
//                       variant="h5"
//                       sx={{
//                         pt: { xs: "5px", md: "0" },
//                       }}
//                     >
//                       <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
//                         <img
//                           src={MervIcon}
//                           className="log-merv-wallet"
//                           style={{
//                             height: "22px",
//                             width: "22px",
//                             position: "relative",
//                           }}
//                         />{" "}
//                         {coins?.data?.data}
//                       </Typography>
//                       <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
//                       <img
//                         src={yellowt}
//                         className="log-merv-wallet"
//                         style={{
//                           height: "22px",
//                           width: "22px",
//                           position: "relative",
//                         }}
//                       />
//                       <CurrencyFormat
//                         value={dataWallet?.data?.data?.walletAmount}
//                         thousandSeparator={","}
//                         displayType={"text"}
//                         decimalSeparator={"."}
//                       />
//                       </Typography>
//                     </Typography>
//                 </Item>
//                 {!loading ? (
//                   <Box>
//                     <input
//                       placeholder="Search"
//                       value={value}
//                       onChange={(e) => setValue(e.target.value)}
//                       className="search"
//                     />
//                   </Box>
//                 ) : (
//                   ""
//                 )}

//                 <MantineProvider
//                   withGlobalStyles
//                   withNormalizeCSS
//                   theme={{
//                     breakpoints: {
//                       xs: "30em",
//                       sm: "48em",
//                       md: "64em",
//                       lg: "74em",
//                       xl: "90em",
//                     },
//                     fontSizes: { xs: "12px", md: "14px" },
//                     colorScheme: "dark",
//                   }}
//                 >
//                   {loading ? (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         height: "300px", // Adjust height as needed
//                       }}
//                     >
//                       <CircularProgress />
//                     </Box>
//                   ) : (
//                     <MantineReactTable
//                       columns={columns}
//                       data={transformData(data) || []}
//                       enablePagination={false}
//                       initialState={{ showColumnFilters: true }}
//                       positionToolbarAlertBanner="bottom"
//                     />
//                   )}
//                 </MantineProvider>

//                 {loading ? (
//                   <Box className="paginationBox">""</Box>
//                 ) : (
//                   <Box className="paginationBox">
//                     <Pagination
//                       pageSize={countPerPage}
//                       onChange={updatePage}
//                       current={currentPage}
//                       total={totalRecords}
//                     />
//                   </Box>
//                 )}
//               </Stack>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };
// export default MyPayments;

import React, { useCallback, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { allData } from "./constants";
import storage from "@/utils/storage";
import axios from "axios";
import "./transaction.css";
import MervIcon from "@/assets/mervIcon.svg";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
import { useInvestedCoins } from "../api/getInvestedCoins";
import yellowt from "@/assets/yellow-t.png";
import CurrencyFormat from "react-currency-format";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import moment from "moment";
const tableHead = {
  txn_id: "Transaction Id",
  currency: "Currency",
  amount: "Amount",
  address: "Payment Address",
  status_text: "Status",
  createdAt: "Payment Date",
};

const MyPayments = () => {
  const countPerPage = 10;
  const dataWallet = useWalletAmount({});
  const coins = useInvestedCoins({});
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState();
  console.log(totalRecords, "totalRecords");
  const [data, setData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  console.log(loading, "loading");

  useEffect(() => {
    getData(currentPage, value);
  }, [currentPage, value]);

  const getData = async (page, searchQuery) => {
    try {
      setLoading(true);

      const token = storage.getToken();
      const response = await axios.get(
        `${process.env.API_URL_PREFIX}/api/v1/user/getDepositWithdrawList`,
        {
          params: {
            limit: countPerPage,
            page: page,
            search: searchQuery || "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      setData(response?.data?.ipnData || []);
      setTotalRecords(response?.data?.metaData?.[0]?.totalDoc || 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = useCallback(
    throttle((searchTerm) => {
      setValue(searchTerm);
      setCurrentPage(1); // Reset to first page on new search
    }, 500),
    []
  );

  // Function to update the page number
  const updatePage = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "N/A";
  };
  

  // const tableRows = (rowData) => {
  //   const { key, index } = rowData;
  //   const tableCell = Object.keys(tableHead);
  //   const columnData = tableCell.map((keyD, i) => {
  //     return (
  //       <td key={i} className="td">
  //         {key[keyD]}
  //       </td>
  //     );
  //   });
  //   return (
  //     <tr key={index} style={{ textAlign: "center" }}>
  //       {columnData}
  //     </tr>

     
  //   );
  // };


  const tableRows = ({ key, index }) => {
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      let cellValue = key[keyD];
  
      // Format date for createdAt column
      if (keyD === "createdAt") {
        cellValue = cellValue ? moment(cellValue).format("DD/MM/YYYY") : "N/A";
      }
  
      return (
        <td key={i} className="td">
          {cellValue}
        </td>
      );
    });
  
    return (
      <tr key={index} style={{ textAlign: "center" }}>
        {columnData}
      </tr>
    );
  };
  

  const tableData = () => {
    return data.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>
        <div className="m-auto minw-maxcontent">{title}</div>
      </td>
    ));
  };

  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));
  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "auto",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };
  return (
    <>
      <input
        placeholder="Search"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="search"
      />
      <Box sx={{ height: "auto" }}>
        <Grid container sx={{ mt: { md: "4px", xs: "10px" } }}>
          <Grid item xs={12} md={12}>
            {" "}
            <Card
              style={cardStyle}
              sx={{
                p: { md: "2px", xs: "0px" },
                m: "2px",
              }}
            >
              <CardContent>
                <Stack spacing={2}>
                  <Item
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <Typography
                      component="h4"
                      sx={{
                        fontSize: { xs: "20px", md: "22px" },
                      }}
                    >
                      My Payments
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        position: "absolute",
                        right: "10%",
                        margin: "-18px",
                        pt: { xs: "5px", md: "0" },
                      }}
                     
                    >
                      <img
                        src={MervIcon}
                        className="log-merv-wallet"
                        style={{
                          height: "22px",
                          width: "22px",
                          position: "relative",
                          top: "5px",
                        }}
                      />{" "}
                      {coins?.data?.data} <br />
                      <img
                        src={yellowt}
                        className="log-merv-wallet"
                        style={{
                          height: "22px",
                          width: "22px",
                          position: "relative",
                          top: "5px",
                          right: "5px",
                        }}
                      />
                      {/* {dataWallet?.data?.data?.walletAmount} */}
                      <CurrencyFormat
                        value={dataWallet?.data?.data?.walletAmount}
                        thousandSeparator={"."}
                        displayType={"text"}
                        decimalSeparator={","}
                      />
                    </Typography>
                  </Item>

                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px",
                      }}
                      className="table"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <table
                        cellPadding="5"
                        cellSpacing="10"
                        className="table MyTransactionList"
                      >
                        <thead>
                          <tr style={{ textAlign: "center" }}>{headRow()}</tr>
                        </thead>
                        <tbody className="trhover">{tableData()}</tbody>
                      </table>
                      {value == "" ? (
                        <Pagination
                          pageSize={countPerPage}
                          onChange={updatePage}
                          current={currentPage}
                          total={totalRecords}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default MyPayments;

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
  Menu,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";

import moment from "moment";
import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import cryptoPayments from "@/assets/cryptoPayments.svg";
import allPayments from "@/assets/allPayments.svg";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
import { MantineProvider, Table, Container } from "@mantine/core";
import { data } from "./makeData";
import { Title } from "@material-ui/icons";
import { DatePickerInput } from "@mantine/dates";
import { useLotteryList } from "../api/getLotteryList";
import { useInvestedCoins } from "../api/getInvestedCoins";
import yellowt from "@/assets/yellow-t.png";
import CurrencyFormat from "react-currency-format";
import MervIcon from "@/assets/mervIcon.svg";
import "./myLottery.css";
import Pagination from "rc-pagination";

export type Payment = {
  endDate: Date;
  endTime: Date;
  lotteryDrawDate: string;
  lotteryImage: string;
  claimStatus?: string;
  transactionId: string;
  LotteryName: string;
  ticketNumber: string;
  createdDate: string;
  amount: number;
  winAmount: string;
  surpriceAmt: number;
  lotteryStatus: string;
};

export const MyLottery = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const dataWallet = useWalletAmount({});
  const coins = useInvestedCoins({});
  const { data, isLoading, isError, isFetching }: any = useLotteryList({
    page,
    limit,
  });
  console.log(data , "data");
  const lotteryData = data?.data || [];
  const pageCount = data?.pagination?.totalPages || 0;
  const currentPage = data?.pagination?.currentPage || 0;
  const totalItems = data?.pagination?.totalItems || 0;
  const handlePageChange = async (newPage: number) => {
    setLoading(true);
    setPage(newPage);
    setLoading(false);
  };
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: limit,
  });
  const Item = styled(Paper)(() => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "auto",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  const tableHead = {
   
    LotteryName: "Lottery Name",
    ticketNumber: "Ticket Number",
    createdDate: "Created At",
    amount:"Ticket Price",
    winAmount:"Win Amount",
    surpriceAmt:"Free Ticket Amount",
    lotteryStatus:"Status"
 
    
  };
  const getStatusStyle = (status: string): React.CSSProperties => {
    switch (status) {
      case "Winner":
        return {
          color: "#95D23D",
          borderRadius: "4px",
          border: "1px solid rgba(149, 210, 61, 0.41)",
          backgroundColor: "rgba(149, 210, 61, 0.19)",
          padding: "4px",
          display: "inline-block",
        };
      case "Pending":
        return {
          color: "#FF9900",
          borderRadius: "4px",
          border: "1px solid rgba(231, 214, 61, 0.5)",
          backgroundColor: "rgba(231, 214, 61, 0.1)",
          padding: "4px",
          display: "inline-block",
        };
      default:
        return {
          color: "#FF4545",
          borderRadius: "4px",
          border: "1px solid rgba(255, 69, 69, 0.4)",
          backgroundColor: "rgba(255, 69, 69, 0.05)",
          padding: "4px",
          display: "inline-block",
        };
    }
  };
  const excludedColumns = ["ticketNumber", "createdDate"];
    const tableRows = (rowData: { key: Record<string, any>; index: number }) => {
      const { key, index } = rowData;
      const tableCell = Object.keys(tableHead);
  
      const columnData = tableCell.map((keyD, i) => {
        let cellStyle: React.CSSProperties = {};
        let value = key[keyD];
         if (keyD === "createdDate") {
              value = moment(value).format("DD/MM/YYYY");
            }
        const numericValue = parseFloat(value);
        if (
          !excludedColumns.includes(keyD) &&
          !isNaN(numericValue) &&
          numericValue !== 0
        ) {
          cellStyle.color = numericValue < 0 ? "red" : "green";
          cellStyle.fontWeight = "bold";
        }
        return (
          <td key={i} className="td" style={cellStyle}>
           {keyD === "lotteryStatus" ? <span style={getStatusStyle(value)}>{value}</span> : value}
          </td>
        );
      });
  
      return (
        <tr key={index} style={{ textAlign: "center" }}>
          {columnData}
        </tr>
      );
    };
  
    const tableData = () => {
      return lotteryData.map((key, index) => tableRows({ key, index }));
    };
  
    const headRow = () => {
      return Object.values(tableHead).map((title, index) => (
        <td key={index}>
          <div className="m-auto minw-maxcontent">{title}</div>
        </td>
      ));
    };

  // const columns = useMemo<MRT_ColumnDef<Payment>[]>(
  //   () => [
  //     {
  //       accessorKey: "LotteryName",
  //       filterVariant: "text",
  //       header: "Lottery Name",
  //       size: 20,
  //     },
  //     {
  //       accessorKey: "ticketNumber",
  //       filterVariant: "text",
  //       header: "Ticket Number",
  //       size: 20,
  //     },
  //     // {
  //     //   accessorFn: (row) => new Date(row.createdDate),
  //     //   id: "Date",
  //     //   header: "Date",
  //     //   filterFn: "lessThanOrEqualTo",
  //     //   sortingFn: "datetime",
  //     //   size: 20,
  //     //   Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
  //     //   Header: ({ column }) => <em>{column.columnDef.header}</em>,

  //     //   Filter: ({ column }) => (
  //     //     <DatePickerInput
  //     //       clearable
  //     //       onChange={(newValue: Date) => {
  //     //         column.setFilterValue(newValue);
  //     //       }}
  //     //       value={column.getFilterValue() as Date}
  //     //       modalProps={{ withinPortal: true }}
  //     //     />
  //     //   ),
  //     // },

  //     {
  //       accessorKey: "createdDate",
  //       filterVariant: "text",
  //       header: "Created At",
  //       size: 20,
  //       Cell: ({ cell }) => {
  //         const formattedDate = moment(cell.getValue() as string).format(
  //           "DD/MM/YYYY"
  //         ); // Change this format to your desired one
  //         return <Box>{formattedDate}</Box>;
  //       },
  //     },

  //     {
  //       accessorKey: "amount",
  //       header: "Ticket Price",
  //       size: 70,
  //       Cell: ({ cell }) => (
  //         <Box>
  //           {cell.getValue<number>()?.toLocaleString?.("en-US", {
  //             style: "currency",
  //             currency: "USD",
  //             minimumFractionDigits: 0,
  //             maximumFractionDigits: 0,
  //           })}
  //         </Box>
  //       ),
  //     },
  //     {
  //       accessorKey: "winAmount",
  //       header: "Win Amount",
  //       size: 70,
  //       Cell: ({ cell }) => (
  //         <Box>
  //           {cell.getValue<number>()?.toLocaleString?.("en-US", {
  //             style: "currency",
  //             currency: "USD",
  //             minimumFractionDigits: 0,
  //             maximumFractionDigits: 0,
  //           })}
  //         </Box>
  //       ),
  //     },

  //     {
  //       accessorKey: "surpriceAmt",
  //       header: "Free Ticket Amount",
  //       size: 50,
  //       Cell: ({ cell }) => (
  //         <Box>
  //           {cell.getValue<number>()?.toLocaleString?.("en-US", {
  //             style: "currency",
  //             currency: "USD",
  //             minimumFractionDigits: 0,
  //             maximumFractionDigits: 0,
  //           })}
  //         </Box>
  //       ),
  //     },

  //     {
  //       accessorKey: "lotteryStatus",
  //       header: "Status",
  //       size: 70,
  //       Cell: ({ cell }) => (
  //         <Box
  //           sx={() => ({
  //             color:
  //               cell.getValue<string>() === "Winner"
  //                 ? "#95D23D"
  //                 : cell.getValue<string>() === "Loose"
  //                 ? "#FF4545"
  //                 : cell.getValue<string>() === "Pending"
  //                 ? "#FF9900"
  //                 : "#FF4545",
  //             borderRadius: "4px",
  //             border:
  //               cell.getValue<string>() === "Winner"
  //                 ? "1px solid rgba(149, 210, 61, 0.41)"
  //                 : cell.getValue<string>() === "Loose"
  //                 ? "1px solid rgba(255, 69, 69, 0.4)"
  //                 : cell.getValue<string>() === "Pending"
  //                 ? "1px solid rgba(231, 214, 61, 0.5)"
  //                 : "1px solid rgba(255, 69, 69, 0.4)",
  //             backgroundColor:
  //               cell.getValue<string>() === "Winner"
  //                 ? "rgba(149, 210, 61, 0.19)"
  //                 : cell.getValue<string>() === "Loose"
  //                 ? "rgba(255, 69, 69, 0.05)"
  //                 : cell.getValue<string>() === "Pending"
  //                 ? "rgba(231, 214, 61, 0.1)"
  //                 : "rgba(255, 69, 69, 0.05)",
  //             maxWidth: "9ch",
  //             padding: "4px",
  //           })}
  //         >
  //           {cell.getValue<string>() === "Pending"
  //             ? "Pending"
  //             : cell.getValue<string>()}
  //         </Box>
  //       ),
  //     },
  //   ],
  //   []
  // );

  return (
    <Box sx={{ height: "auto" }}>
      <Grid container sx={{ mt: { md: theme.spacing(0.5), xs: "10px" } }}>
        <Grid item xs={12} md={12}>
          {" "}
          <Card
            style={cardStyle}
            sx={{
              p: { md: theme.spacing(0.3), xs: "0px" },
              m: theme.spacing(0.2),
            }}
          >
            <CardContent>
              <Stack spacing={2}>
                <Item sx={{alignItems:"center", justifyContent:"space-between"}}>
                  {" "}
                  <Typography
                    component="h4"
                    sx={{
                      fontSize: { xs: "20px", md: "22px" },
                    }}
                  >
                    My Lottery 
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{  
                      pt: { xs: "5px", md: "0" },
                    }}
                  >
                    <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                      <img
                        src={MervIcon}
                        className="log-merv-wallet"
                        style={{
                          height: "22px",
                          width: "22px",
                          position: "relative", 
                        }}
                      />{" "}
                      {coins?.data?.data}  
                    </Typography>
                    <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                    <img
                      src={yellowt}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px",
                        position: "relative", 
                      }}
                    />
                    <CurrencyFormat
                      value={dataWallet?.data?.data?.walletAmount}
                      thousandSeparator={","}
                      displayType={"text"}
                      decimalSeparator={"."}
                    />
                    </Typography>
                  </Typography>
                </Item>
                <Grid item sx={{ width: "100%", overflowX: "auto" }}>
                  <MantineProvider
                    withGlobalStyles
                    withNormalizeCSS
                    theme={{
                      breakpoints: {
                        xs: "30em",
                        sm: "48em",
                        md: "64em",
                        lg: "74em",
                        xl: "90em",
                      },
                      fontSizes: { xs: "12px", md: "14px" },
                      colorScheme: "dark",
                    }}
                  >
                    {isFetching ? ( 
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "200px",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      // <MantineReactTable
                      //   columns={columns}
                      //   data={lotteryData || []}
                      //   enablePagination={false}
                      //   initialState={{ showColumnFilters: true }}
                      //   positionToolbarAlertBanner="bottom"
                      // />

                      <table
                      cellPadding="5"
                      cellSpacing="10"
                      className="table MyLotteryList"
                    >
                      <thead>
                        <tr style={{ textAlign: "center" }}>{headRow()}</tr>
                      </thead>
                      <tbody className="trhover">{tableData()}</tbody>
                    </table>
                    )}
                  </MantineProvider>
                </Grid>
                {isFetching ? (
                  <Box className="paginationBox">""</Box>
                ) : (
                  <Box className="paginationBox">
                    <Pagination
                      pageSize={pageCount}
                      onChange={handlePageChange}
                      current={currentPage}
                      total={totalItems}
                    />
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};



// import {
//   Box,
//   Card,
//   CardContent,
//   CardHeader,
//   Paper,
//   FormControl,
//   InputLabel,
//   Stack,
//   Typography,
//   Divider,
//   CardMedia,
//   Button,
//   Icon,
//   InputAdornment,
//   TextField,
//   Menu,
//   useMediaQuery,
// } from "@mui/material";

// import { Grid, Select, MenuItem } from "@mui/material";
// import { useEffect, useMemo, useRef, useState } from "react";
// import { styled } from "@mui/material/styles";
// import { theme } from "@/components/Elements/theme";
// import cryptoPayments from "@/assets/cryptoPayments.svg";
// import allPayments from "@/assets/allPayments.svg";
// import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
// import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
// import { MantineProvider } from "@mantine/core";
// import { data } from "./makeData";
// import { Title } from "@material-ui/icons";
// import { DatePickerInput } from "@mantine/dates";
// import { useLotteryList } from "../api/getLotteryList";
// import { useInvestedCoins } from "../api/getInvestedCoins";
// import yellowt from "@/assets/yellow-t.png";
// import CurrencyFormat from 'react-currency-format';
// import MervIcon from "@/assets/mervIcon.svg";
// import "./table.css";

// export type Payment = {
//   endDate: Date;
//   endTime: Date;
//   lotteryDrawDate: string;
//   lotteryImage: string;
//   transactionId: string;
//   LotteryName: string;
//   ticketNumber: string;
//   createdDate: string;
//   amount: number;
//   winAmount: string;
//   surpriceAmt: number;
//   lotteryStatus: string;
// };
// //nested data is ok, see accessorKeys in ColumnDef below

// export const MyLottery = () => {
//     const [page, setPage] = useState(1);
//   const [limit, setLimit] = useState(10);
//   const dataWallet = useWalletAmount({});
//   const coins = useInvestedCoins({});
//   const { data, isLoading, isError, isFetching }: any = useLotteryList({
//     page,
//     limit,
//   });
//   const lotteryData = data?.data || [];
//   const Item = styled(Paper)(() => ({
//     background: "transparent",
//     display: "flex",
//     justifyContent: "flex-start",
//     alignItems: "flex-start",
//   }));

//   var cardStyle = {
//     display: "block",
//     transitionDuration: "0.3s",
//     height: "auto",
//     background: "#050518",
//     boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
//     borderRadius: "20px",
//   };

//   const columns = useMemo<MRT_ColumnDef<Payment>[]>(
//     () => [
//       // {
//       //   accessorFn: () => 'lotteryImage', //accessorFn used to join multiple data into a single cell
//       //   id: 'Lottery Image', //id is still required when using accessorFn instead of accessorKey
//       //   header: 'Lottery Image',
//       //   enableColumnFilter: false,
//       //   size: 20,
//       //   width: 40,
//       //   Cell: ({ row }) => (
//       //     <Box
//       //       sx={{
//       //         display: 'flex',
//       //         alignItems: 'center',
//       //         gap: '16px',
//       //       }}
//       //     >
//       //       <img
//       //         alt="avatar"
//       //         height={30}
//       //         src={`https://cryptolotteryapi.dev.vinove.com/api/v1/admin/uploads/${row.original.lotteryImage}`}
//       //         style={{ borderRadius: '50%' }}
//       //       />
//       //     </Box>
//       //   ),
//       // },
//       // {
//       //   accessorKey: 'transactionId',
//       //   filterVariant: 'text',
//       //   header: 'Lottery Id',
//       //   size: 20,
//       //   //custom conditional format and styling
//       // },
//       {
//         accessorKey: "LotteryName",
//         filterVariant: "text",
//         header: "Lottery Name",
//         size: 20,
//         //custom conditional format and styling
//       },
//       {
//         accessorKey: "ticketNumber",
//         filterVariant: "text",
//         header: "Ticket Number",
//         size: 20,
//         //custom conditional format and styling
//       },
//       {
//         accessorFn: (row) => new Date(row.createdDate), //convert to Date for sorting and filtering
//         id: "Date",
//         header: "Date",
//         filterFn: "lessThanOrEqualTo",
//         sortingFn: "datetime",
//         size: 180,
//         Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
//         Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
//         //Custom Date Picker Filter from @mantine/dates
//         Filter: ({ column }) => (
//           <DatePickerInput
//             // placeholder="Filter by Start Date"
//             clearable
//             onChange={(newValue: Date) => {
//               column.setFilterValue(newValue);
//             }}
//             value={column.getFilterValue() as Date}
//             modalProps={{ withinPortal: true }}
//           />
//         ),
//       },
//       {
//         accessorFn: (row) =>
//           row?.createdDate
//             ? new Date(row?.createdDate)
//                 ?.toISOString()
//                 .split("T")[1]
//                 .substring(0, 5)
//             : "-",
//         filterVariant: "text",
//         header: "Time",
//         size: 70,
//         //custom conditional format and styling
//       },
//       {
//         accessorKey: "amount", //hey a simple column for once
//         header: "Ticket Price",
//         size: 70,
//         Cell: ({ cell }) => (
//           <Box>
//             {cell.getValue<number>()?.toLocaleString?.("en-US", {
//               style: "currency",
//               currency: "USD",
//               minimumFractionDigits: 0,
//               maximumFractionDigits: 0,
//             })}
//           </Box>
//         ),
//       },
//       {
//         accessorKey: "winAmount", //hey a simple column for once
//         header: "Win Amount",
//         size: 70,
//         Cell: ({ cell }) => (
//           <Box>
//             {cell.getValue<number>()?.toLocaleString?.("en-US", {
//               style: "currency",
//               currency: "USD",
//               minimumFractionDigits: 0,
//               maximumFractionDigits: 0,
//             })}
//           </Box>
//         ),
//       },
//       {
//         accessorKey: "winAmount", //hey a simple column for once
//         header: "Claim",
//         size: 70,
//         Cell: ({ cell }) => (
//           <>
//             {cell.getValue<number>() > 0 ? (
//               <Box
//                 sx={{
//                   px: "14px",
//                   py: "5.73px",
//                   marginBottom: "5px !important",
//                   background: "rgb(60, 55, 255)",
//                   borderRadius: "15.507px",
//                   color: "#fff !important",
//                   width: "115px !important",
//                   textAlign: "center",
//                   fontWeight: "600",
//                 }}
//               >
//                 Claim
//               </Box>
//             ) : (
//               <Box
//                 sx={{
//                   px: "14px",
//                   py: "5.73px",
//                   marginBottom: "5px !important",
//                   background: "rgba(255, 255, 255, 0.5)",
//                   borderRadius: "15.507px",
//                   color: "#3C37FF !important",
//                   width: "115px !important",
//                   textAlign: "center",
//                   fontWeight: "600",
//                 }}
//               >
//                 Claim
//               </Box>
//             )}
//           </>
//         ),
//       },
//       {
//         accessorKey: "surpriceAmt", //hey a simple column for once
//         header: "Free Ticket Amount",
//         size: 50,
//         Cell: ({ cell }) => (
//           <Box>
//             {cell.getValue<number>()?.toLocaleString?.("en-US", {
//               style: "currency",
//               currency: "USD",
//               minimumFractionDigits: 0,
//               maximumFractionDigits: 0,
//             })}
//           </Box>
//         ),
//       },
//       {
//         accessorKey: "surpriceAmt", //hey a simple column for once
//         header: "Redeem",
//         size: 70,
//         Cell: ({ cell }) => (
//           <>
//             {cell.getValue<number>() > 0 ? (
//               <Box
//                 sx={{
//                   px: "14px",
//                   py: "5.73px",
//                   marginBottom: "5px !important",
//                   background: "rgb(60, 55, 255)",
//                   borderRadius: "15.507px",
//                   color: "#fff !important",
//                   width: "115px !important",
//                   textAlign: "center",
//                   fontWeight: "600",
//                 }}
//               >
//                 Redeem
//               </Box>
//             ) : (
//               <Box
//                 sx={{
//                   px: "14px",
//                   py: "5.73px",
//                   marginBottom: "5px !important",
//                   background: "rgba(255, 255, 255, 0.5)",
//                   borderRadius: "15.507px",
//                   color: "#3C37FF !important",
//                   width: "115px !important",
//                   textAlign: "center",
//                   fontWeight: "600",
//                 }}
//               >
//                 Redeem
//               </Box>
//             )}
//           </>
//         ),
//       },
//       {
//         accessorKey: "lotteryStatus", //hey a simple column for once
//         header: "Status",
//         size: 70,
//         Cell: ({ cell }) => (
//           <Box
//             sx={() => ({
//               color:
//                 cell.getValue<string>() === "Winner"
//                   ? "#95D23D"
//                   : cell.getValue<string>() === "Loose"
//                   ? "#FF9900"
//                   : "#FF4545",
//               borderRadius: "4px",
//               border:
//                 cell.getValue<string>() === "Winner"
//                   ? "1px solid rgba(149, 210, 61, 0.41)"
//                   : cell.getValue<string>() === "Loose"
//                   ? "1px solid rgba(231, 214, 61, 0.5)"
//                   : "1px solid rgba(255, 69, 69, 0.4)",
//               backgroundColor:
//                 cell.getValue<string>() === "Winner"
//                   ? "rgba(149, 210, 61, 0.19)"
//                   : cell.getValue<string>() === "Pending"
//                   ? "rgba(231, 214, 61, 0.1)"
//                   : "rgba(255, 69, 69, 0.05)",
//               maxWidth: "9ch",
//               padding: "4px",
//             })}
//           >
//             {cell.getValue<string>() === "Pending"
//               ? "N/A"
//               : cell.getValue<string>()}
//           </Box>
//         ),
//       },
//     ],
//     []
//   );

//   return (
//     <Box sx={{ height: "auto" }}>
//       <Grid container sx={{ mt: { md: "theme.spacing(0.5)", xs: "10px" } }}>
//         <Grid item xs={12} md={12}>
//           {" "}
//           <Card
//             style={cardStyle}
//             sx={{
//               p: { md: "theme.spacing(0.3)", xs: "0px" },
//               m: theme.spacing(0.2),
//             }}
//           >
//             <CardContent>
//               <Stack spacing={2}>
//                 <Item>
//                   {" "}
//                   <Typography variant="h5">My Lottery</Typography>
//                   <Typography
//                     variant="h5"
//                     sx={{
//                       position: "absolute",
//                       right: "10%",
//                       margin: "-18px",
//                       pt: { xs: "5px", md: "0" },
//                     }}
//                   >
//                     <img
//                       src={MervIcon}
//                       className="log-merv-wallet"
//                       style={{
//                         height: "22px",
//                         width: "22px",
//                         position: "relative",
//                         top: "5px",
//                       }}
//                     />{" "}
//                     {coins?.data?.data} <br />
//                     <img
//                       src={yellowt}
//                       className="log-merv-wallet"
//                       style={{
//                         height: "22px",
//                         width: "22px",
//                         position: "relative",
//                         top: "5px",
//                         right: "5px",
//                       }}
//                     />
//                     {/* {dataWallet?.data?.data?.walletAmount} */}
//                     <CurrencyFormat value={dataWallet?.data?.data?.walletAmount} thousandSeparator={'.'} displayType={'text'} decimalSeparator={','} />
//                   </Typography>
//                 </Item>
//                 <Item>
//                   <Grid item sx={{ width: "100%", overflowX: "auto" }}>
//                     <MantineProvider
//                       theme={{
//                         colorScheme: "dark",
//                       }}
//                     >
//                       {lotteryData ? (
//                         <MantineReactTable
//                           columns={columns}
//                           data={lotteryData|| []}
//                           initialState={{ showColumnFilters: true }}
//                           positionPagination="top"
//                           positionToolbarAlertBanner="bottom"
//                           renderDetailPanel={({ row }) => (
//                             <>
//                               {console.log(
//                                 row.original.transactionId,
//                                 "qwertyuio"
//                               )}
//                               <Box
//                                 sx={{
//                                   backgroundColor: "rgba(5, 5, 24, 0.8)",
//                                   borderRadius: "10px",
//                                 }}
//                               />
//                               <Box sx={{}}>
//                                 <Card
//                                   sx={{ margin: "0 auto", background: "none" }}
//                                 >
//                                   <div
//                                     style={{
//                                       display: "flex",
//                                       flexDirection: "row",
//                                       alignItems: "flex-left",
//                                     }}
//                                   >
//                                     <img
//                                       src={`${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${row.original.lotteryImage}`}
//                                       alt="lottery name"
//                                       style={{
//                                         maxWidth: "100px",
//                                         width: "100%",
//                                         marginLeft: "126px",
//                                       }}
//                                     />
//                                     {/* {
//                                       "lotteryImage": "Daily 10_1690461956362.png",
//                                       "LotteryName": "Daily 300",
//                                       "ticketNumber": "000017",
//                                       "createdDate": "2023-07-28T11:00:45.653Z",
//                                       "amount": 4000,
//                                       "winAmount": "",
//                                       "lotteryStatus": "Pending"
//                                     } */}
//                                     <div
//                                       style={{
//                                         maxWidth: "700px",
//                                         color: "white",
//                                         marginLeft: "20px",
//                                       }}
//                                     >
//                                       <p
//                                         style={{
//                                           fontSize: "14px",
//                                           fontWeight: 700,
//                                           lineHeight: "130%",
//                                         }}
//                                       >
//                                         Ticket sale close at
//                                         <span
//                                           style={{
//                                             color: "#3C37FF",
//                                             marginLeft: "4px",
//                                           }}
//                                         >
//                                           {row?.original?.endDate?.toString()}{" "}
//                                           {row?.original?.endTime?.toString()}
//                                         </span>
//                                       </p>
//                                       <p
//                                         style={{
//                                           fontSize: "14px",
//                                           fontWeight: 700,
//                                           lineHeight: "130%",
//                                         }}
//                                       >
//                                         Draw Date
//                                         <span
//                                           style={{
//                                             color: "#3C37FF",
//                                             marginLeft: "4px",
//                                           }}
//                                         >
//                                           {" "}
//                                           {row.original?.lotteryDrawDate
//                                             .split("T")[0]
//                                             .substring(0, 10)}
//                                         </span>
//                                       </p>
//                                       <p
//                                         style={{
//                                           fontSize: "14px",
//                                           fontWeight: 700,
//                                           lineHeight: "130%",
//                                         }}
//                                       >
//                                         Draw Time
//                                         <span
//                                           style={{
//                                             color: "#3C37FF",
//                                             marginLeft: "4px",
//                                           }}
//                                         >
//                                           {" "}
//                                           {row.original?.lotteryDrawDate
//                                             .split("T")[1]
//                                             .substring(0, 5)}
//                                         </span>
//                                       </p>
//                                     </div>
//                                     <div
//                                       style={{
//                                         maxWidth: "700px",
//                                         color: "white",
//                                         marginLeft: "45px",
//                                       }}
//                                     >
//                                       <p
//                                         style={{
//                                           fontSize: "14px",
//                                           fontWeight: 700,
//                                           lineHeight: "130%",
//                                         }}
//                                       >
//                                         Ticket Number
//                                         <span
//                                           style={{
//                                             color: "#3C37FF",
//                                             marginLeft: "4px",
//                                           }}
//                                         >
//                                           {row.original.ticketNumber === ""
//                                             ? "Pending"
//                                             : row.original.ticketNumber}
//                                         </span>
//                                       </p>
//                                     </div>
//                                     <div
//                                       style={{
//                                         maxWidth: "700px",
//                                         color: "white",
//                                         paddingLeft: "70px",
//                                         marginTop: "120px",
//                                       }}
//                                     >
//                                       <p
//                                         style={{
//                                           fontSize: "14px",
//                                           fontWeight: 700,
//                                           lineHeight: "130%",
//                                         }}
//                                       >
//                                         Note:
//                                         <span
//                                           style={{
//                                             color: "#3C37FF",
//                                             marginLeft: "4px",
//                                           }}
//                                         >
//                                           Ticket number will be randomly
//                                           allocated after ticket sale is closed.
//                                         </span>
//                                       </p>
//                                     </div>
//                                   </div>
//                                 </Card>
//                               </Box>
//                             </>
//                           )}
//                         />
//                       ) : (
//                         <div className="circular-progress1">
//                           <div className="circle"></div>
//                           <div className="mask full">
//                             <div className="fill"></div>
//                           </div>
//                           <div className="mask half">
//                             <div className="fill"></div>
//                             <div className="fill fix"></div>
//                           </div>
//                         </div>
//                       )}
//                     </MantineProvider>
//                   </Grid>
//                 </Item>
//               </Stack>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

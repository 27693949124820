// import React, { useEffect, useMemo, useState } from "react";
// import cloneDeep from "lodash/cloneDeep";
// import throttle from "lodash/throttle";
// import Pagination from "rc-pagination";
// import "rc-pagination/assets/index.css";
// import { allData } from "./constants";
// import storage from "@/utils/storage";
// import axios from "axios";
// import "./transaction.css";
// import {
//   Box,
//   Card,
//   CardContent,
//   CircularProgress,
//   Grid,
//   Paper,
//   Stack,
//   styled,
//   Typography,
//   useTheme,
// } from "@mui/material";
// import MervIcon from "@/assets/mervIcon.svg";
// import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
// import { useInvestedCoins } from "../api/getInvestedCoins";
// import yellowt from "@/assets/yellow-t.png";
// import CurrencyFormat from "react-currency-format";
// import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";
// import { MantineProvider } from "@mantine/core";

// const MyTransactionList = () => {
//   const countPerPage = 10;
//   const dataWallet = useWalletAmount({});
//   const coins = useInvestedCoins({});
//   const [value, setValue] = React.useState("");
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const [totalRecords, setTotalRecords] = React.useState(10);
//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState([
//     {
//       LotteryName: "",
//       amount: 0,
//       commissionAmount: "",
//       createdDate: "",
//       lotteryWinningPrize: "0",
//       payoutAmount: "",
//       transactionId: "",
//       transactionStatus: "",
//       userName: "",
//       ticketNumber: "",
//     },
//   ]);

//   const transformData: any = (data) => {
//     return data.map((item) => {
//       return {
//         LotteryName: item.LotteryName || "N/A",
//         amount: item.amount || "N/A",
//         commissionAmount: item.commissionAmount || "N/A",
//         createdDate: item.createdDate || "N/A",
//         lotteryWinningPrize: item.lotteryWinningPrize || "N/A",
//         payoutAmount: item.payoutAmount || "N/A",
//         transactionId: item.transactionId || "N/A",
//         status: item.transactionStatus || "N/A",
//         userName: item.userName || "N/A",
//         ticketNumber: item.ticketNumber || "N/A",
//       };
//     });
//   };
//   const [collection, setCollection] = React.useState(
//     cloneDeep(allData.slice(0, countPerPage))
//   );
//   const searchData = React.useRef(
//     throttle((val) => {
//       const query = val.toLowerCase();
//       setCurrentPage(1);
//       const data = cloneDeep(
//         allData
//           .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
//           .slice(0, countPerPage)
//       );
//       setCollection(data);
//     }, 400)
//   );

//   React.useEffect(() => {
//     if (!value) {
//       updatePage(1);
//     } else {
//       searchData.current(value);
//     }
//   }, [value]);

//   useEffect(() => {
//     getData(value, 1);
//   }, [value]);

//   const getData = async (value, p) => {
//     let token = storage.getToken();
//     let pageLimit;
//     pageLimit = value != "" ? totalRecords : 10;
//     const payable = {
//       page: p,
//       limit: pageLimit,
//     };
//     setLoading(true);
//     await axios
//       .post(
//         `${process.env.API_URL_PREFIX}/api/v1/user/myTransactionList?search=${value}`,
//         payable,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Access-Control-Allow-Origin": "*",
//           },
//         }
//       )
//       .then((res) => {
//         setData(res.data.data);
//         setTotalRecords(res.data.transactionPageCount);
//       })
//       .catch((err) => {
//         console.log(err);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
//   const updatePage = (p) => {
//     setCurrentPage(p);
//     const to = countPerPage * p;
//     const from = to - countPerPage;
//     setCollection(cloneDeep(allData.slice(from, to)));
//     getData(value, p);
//   };

//   const Item = styled(Paper)(({ theme }) => ({
//     background: "transparent",
//     display: "flex",
//     justifyContent: "flex-start",
//     alignItems: "flex-start",
//   }));

//   var cardStyle = {
//     display: "block",
//     transitionDuration: "0.3s",
//     height: "auto",
//     background: "#050518",
//     boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
//     borderRadius: "20px",
//   };

//   const columns = useMemo<MRT_ColumnDef<any>[]>(
//     () => [
//       {
//         accessorKey: "LotteryName",
//         filterVariant: "text",
//         header: "Lottery Name",
//         size: 30,
//       },
//       {
//         accessorKey: "userName",
//         filterVariant: "text",
//         header: "User Name",
//         size: 20,
//       },
//       {
//         accessorKey: "transactionId",
//         filterVariant: "text",
//         header: "Transaction ID",
//         size: 20,
//       },
//       {
//         accessorKey: "createdDate",
//         filterVariant: "text",
//         header: "Created At",
//         size: 20,
//       },
//       {
//         accessorKey: "amount",
//         header: "Amount",
//         size: 40,
//         Cell: ({ cell }) => (
//           <Box>
//             {cell.getValue<number>()?.toLocaleString?.("en-US", {
//               style: "currency",
//               currency: "USD",
//               minimumFractionDigits: 0,
//               maximumFractionDigits: 0,
//             })}
//           </Box>
//         ),
//       },

//       {
//         accessorKey: "lotteryWinningPrize",
//         filterVariant: "text",
//         header: "Winning Prize",
//         size: 20,
//         Cell: ({ cell }) => (
//           <Box>
//             {cell.getValue<number>()?.toLocaleString?.("en-US", {
//               style: "currency",
//               currency: "USD",
//               minimumFractionDigits: 0,
//               maximumFractionDigits: 0,
//             })}
//           </Box>
//         ),
//       },
//       {
//         accessorKey: "payoutAmount",
//         header: "Payout Amount",
//         size: 40,
//         Cell: ({ cell }) => (
//           <Box>
//             {cell.getValue<number>()?.toLocaleString?.("en-US", {
//               style: "currency",
//               currency: "USD",
//               minimumFractionDigits: 0,
//               maximumFractionDigits: 0,
//             })}
//           </Box>
//         ),
//       },
//       {
//         accessorKey: "status",
//         header: "Status",
//         size: 40,
//         Cell: ({ cell }) => (
//           <Box
//             sx={() => ({
//               color:
//                 cell.getValue<string>() === "Approved"
//                   ? "#28A745"
//                   : cell.getValue<string>() === "Rejected"
//                   ? "#DC3545"
//                   : cell.getValue<string>() === "Pending"
//                   ? "#FFC107"
//                   : "#6C757D",
//               borderRadius: "4px",
//               border:
//                 cell.getValue<string>() === "Approved"
//                   ? "1px solid rgba(40, 167, 69, 0.41)"
//                   : cell.getValue<string>() === "Rejected"
//                   ? "1px solid rgba(220, 53, 69, 0.4)"
//                   : cell.getValue<string>() === "Pending"
//                   ? "1px solid rgba(255, 193, 7, 0.4)"
//                   : "1px solid rgba(108, 117, 125, 0.4)",
//               backgroundColor:
//                 cell.getValue<string>() === "Approved"
//                   ? "rgba(40, 167, 69, 0.2)"
//                   : cell.getValue<string>() === "Rejected"
//                   ? "rgba(220, 53, 69, 0.2)"
//                   : cell.getValue<string>() === "Pending"
//                   ? "rgba(255, 193, 7, 0.2)"
//                   : "rgba(108, 117, 125, 0.1)",
//               padding: "4px",
//               textAlign: "center",
//               maxWidth: "10ch",
//             })}
//           >
//             {cell.getValue<string>()}
//           </Box>
//         ),
//       },

//       {
//         accessorKey: "ticketNumber",
//         filterVariant: "text",
//         header: "Ticket No",
//         size: 10,
//       },
//     ],
//     []
//   );

//   return (
//     <Box sx={{ height: "auto" }}>
//       <Grid container sx={{ mt: { md: useTheme().spacing(0.5), xs: "10px" } }}>
//         <Grid item xs={12} md={12}>
//           {" "}
//           <Card
//             style={cardStyle}
//             sx={{
//               p: { md: useTheme().spacing(0.3), xs: "0px" },
//               m: useTheme().spacing(0.2),
//             }}
//           >
//             <CardContent>
//               <Stack spacing={2}>
//                 <Item sx={{alignItems:"center", justifyContent:"space-between"}}>
//                     {" "}
//                     <Typography
//                       component="h4"
//                       sx={{
//                         fontSize: { xs: "20px", md: "22px" },
//                       }}
//                     >
//                       My Transactions
//                     </Typography>
//                     <Typography
//                       variant="h5"
//                       sx={{
//                         pt: { xs: "5px", md: "0" },
//                       }}
//                     >
//                       <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
//                         <img
//                           src={MervIcon}
//                           className="log-merv-wallet"
//                           style={{
//                             height: "22px",
//                             width: "22px",
//                             position: "relative",
//                           }}
//                         />{" "}
//                         {coins?.data?.data}
//                       </Typography>
//                       <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
//                       <img
//                         src={yellowt}
//                         className="log-merv-wallet"
//                         style={{
//                           height: "22px",
//                           width: "22px",
//                           position: "relative",
//                         }}
//                       />
//                       <CurrencyFormat
//                         value={dataWallet?.data?.data?.walletAmount}
//                         thousandSeparator={","}
//                         displayType={"text"}
//                         decimalSeparator={"."}
//                       />
//                       </Typography>
//                     </Typography>
//                 </Item>
//                 {!loading ? (
//                   <Box>
//                     <input
//                       placeholder="Search"
//                       value={value}
//                       onChange={(e) => setValue(e.target.value)}
//                       className="search"
//                     />
//                   </Box>
//                 ) : (
//                   ""
//                 )}

//                 <MantineProvider
//                   withGlobalStyles
//                   withNormalizeCSS
//                   theme={{
//                     breakpoints: {
//                       xs: "30em",
//                       sm: "48em",
//                       md: "64em",
//                       lg: "74em",
//                       xl: "90em",
//                     },
//                     fontSizes: { xs: "12px", md: "14px" },
//                     colorScheme: "dark",
//                   }}
//                 >
//                   {loading ? (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         height: "300px",
//                       }}
//                     >
//                       <CircularProgress />
//                     </Box>
//                   ) : (
//                     <MantineReactTable
//                       columns={columns}
//                       data={transformData(data) || []}
//                       enablePagination={false}
//                       initialState={{ showColumnFilters: true }}
//                       positionToolbarAlertBanner="bottom"
//                     />
//                     // <table cellPadding="5" cellSpacing="10" className="table MyTransactionList">
//                     //   <thead>
//                     //     <tr style={{ textAlign: "center" }}>{headRow()}</tr>
//                     //   </thead>
//                     //   <tbody className="trhover">{tableData()}</tbody>
//                     // </table>
//                   )}
//                 </MantineProvider>

//                 {loading ? (
//                   <Box className="paginationBox">""</Box>
//                 ) : (
//                   <Box className="paginationBox">
//                     <Pagination
//                       pageSize={countPerPage}
//                       onChange={updatePage}
//                       current={currentPage}
//                       total={totalRecords}
//                     />
//                   </Box>
//                 )}
//               </Stack>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };
// export default MyTransactionList;

import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { allData } from "./constants";
import storage from "@/utils/storage";
import axios from "axios";
import "./transaction.css";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import MervIcon from "@/assets/mervIcon.svg";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
import { useInvestedCoins } from "../api/getInvestedCoins";
import yellowt from "@/assets/yellow-t.png";
import CurrencyFormat from "react-currency-format";
const tableHead = {
  transactionId: "Transaction Id",
  LotteryName: "Lottery Name",
  userName: "User Name",
  ticketNumber: "Ticket Number",
  amount: "Amount",
  freeTicketAmt: "Free Ticket Amount",
  lotteryWinningPrize: "Lottery Win Prize",
  commissionAmount: "Commission Amount",
  payoutAmount: "Payout Amount",
  payoutRequest: "Payout Request",
  transactionStatus: "Transaction Status",
  createdDate: "Created Date",
};

const MyTransactionList = () => {
  const countPerPage = 10;
  const dataWallet = useWalletAmount({});
  const coins = useInvestedCoins({});
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(10);
  const [data, setData] = useState([
    {
      LotteryName: "",
      amount: 0,
      commissionAmount: "",
      createdDate: "",
      lotteryWinningPrize: "0",
      payoutAmount: "",
      transactionId: "",
      transactionStatus: "",
      userName: "",
      ticketNumber: "",
    },
  ]);
  console.log(data, "data");
  const [loading, setLoading] = React.useState(false);
  console.log(loading, "loading Loading");
  const [collection, setCollection] = React.useState(
    cloneDeep(allData.slice(0, countPerPage))
  );
  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData
          .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  useEffect(() => {
    getData(value, 1);
  }, [value]);

  const getData = async (value, p) => {
    let token = storage.getToken();
    setLoading(true);
    let pageLimit;
    pageLimit = value != "" ? totalRecords : 10;
    const payable = {
      page: p,
      limit: pageLimit,
    };
    await axios
      .post(
        `${process.env.API_URL_PREFIX}/api/v1/user/myTransactionList?search=${value}`,
        payable,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data.data);
        setTotalRecords(res.data.transactionPageCount);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData.slice(from, to)));
    getData(value, p);
  };
  console.log("?search=", value);

  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));
  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "auto",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  const excludedColumns = ["transactionId", "ticketNumber", "createdDate"];

  const tableRows = (rowData: { key: Record<string, any>; index: number }) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);

    const columnData = tableCell.map((keyD, i) => {
      let cellStyle: React.CSSProperties = {};

      // Convert value to number if possible
      const value = key[keyD];
      const numericValue = parseFloat(value);

      // Apply conditional styling for negative and positive values
      if (
        !excludedColumns.includes(keyD) &&
        !isNaN(numericValue) &&
        numericValue !== 0
      ) {
        cellStyle.color = numericValue < 0 ? "red" : "green";
        cellStyle.fontWeight = "bold";
      }

      return (
        <td key={i} className="td" style={cellStyle}>
          {value}
        </td>
      );
    });

    return (
      <tr key={index} style={{ textAlign: "center" }}>
        {columnData}
      </tr>
    );
  };

  const tableData = () => {
    return data.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>
        <div className="m-auto minw-maxcontent">{title}</div>
      </td>
    ));
  };
  console.log("totalRecords", totalRecords);
  return (
    <>
      <input
        placeholder="Search"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="search"
      />
      <Box sx={{ height: "auto" }}>
        <Grid container sx={{ mt: { md: "4px", xs: "10px" } }}>
          <Grid item xs={12} md={12}>
            {" "}
            <Card
              style={cardStyle}
              sx={{
                p: { md: "2px", xs: "0px" },
                m: "2px",
              }}
            >
              <CardContent>
                <Stack spacing={2}>
                  <Item
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <Typography
                      component="h4"
                      sx={{
                        fontSize: { xs: "20px", md: "22px" },
                      }}
                    >
                      My Transactions
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        position: "absolute",
                        right: "10%",
                        margin: "-18px",
                        pt: { xs: "5px", md: "0" },
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          flexDirection: "column", 
                          alignItems: "center",
                          gap: "5px", 
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={MervIcon}
                            className="log-merv-wallet"
                            style={{
                              height: "22px",
                              width: "22px",
                            }}
                          />
                          {coins?.data?.data}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={yellowt}
                            className="log-merv-wallet"
                            style={{
                              height: "22px",
                              width: "22px",
                            }}
                          />
                          <CurrencyFormat
                            value={dataWallet?.data?.data?.walletAmount}
                            thousandSeparator={"."}
                            displayType={"text"}
                            decimalSeparator={","}
                          />
                        </Box>
                      </Typography>
                    </Typography>
                  </Item>

                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <table
                        cellPadding="5"
                        cellSpacing="10"
                        className="table MyTransactionList"
                      >
                        <thead>
                          <tr style={{ textAlign: "center" }}>{headRow()}</tr>
                        </thead>
                        <tbody className="trhover">{tableData()}</tbody>
                      </table>
                      {value == "" ? (
                        <Box className="paginationBox">
                          <Pagination
                            pageSize={countPerPage}
                            onChange={updatePage}
                            current={currentPage}
                            total={totalRecords}
                          />
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default MyTransactionList;
